body:not(.no-hover) {
  .menu-sidebar-left {
    &:hover {
      @apply w-[230px];

      .group-logos {
        @apply pb-5;
      }

      .logo-full {
        @apply visible opacity-100;
      }

      .sidebar-item .label {
        display: -webkit-box;
        @apply visible opacity-100;

        &.avatar {
          display: block;
        }
      }
    }
  }
}

.menu-sidebar-left {
  transition: width 0.2s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
}

.sidebar-list-items {
  &:hover {
    .sidebar-child.level-1 {
      @apply visible opacity-100;
    }
  }

  .sidebar-child {
    &.level-1 {
      @apply invisible absolute left-full top-0 z-20 w-max rounded-md border border-default bg-white p-4 opacity-0 transition-all duration-300;
      .sidebar-item {
        @apply justify-start;

        .label {
          @apply block;
        }
      }

      .sidebar-list-items {
        @apply mb-3 pl-0 last:mb-0;
      }
    }

    &.level-2 {
      @apply invisible h-0 opacity-0 transition-all duration-100;

      &.active {
        @apply visible h-full opacity-100;

        .sidebar-list-items {
          @apply pl-0;
        }
      }

      .menu-items-list {
        @apply p-4 pb-0;
      }

      .sidebar-list-items {
        @apply mb-3 last:mb-0;
      }
    }
  }
}
