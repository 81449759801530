.text-red-400 {
  color: #f56565;
}

// calculator
$color-white: #ffffff;
$color-display: #4a4e69;
$color-btns-container: #fef3eb;
$color-calc-btn: #d7d7d7;
$color-calc-btn-colored: #ffb685;
$color-warning: rgb(255, 53, 53);
$color-background-dark: #22223b;
$color-display-dark: #4a4e69;
$color-btns-container-dark: #fdcdad;
$color-calc-btn-dark: #f2e9e4;
$color-calc-btn-colored-dark: #ffb685;
$color-blue-shade-dark: #c3c6d5;
$color-light: #f2e9e4;
